<template>
  <b-modal
    v-model="show"
    id="modal-artifacts"
    size="lg"
    title="Artifacts"
    header-bg-variant="secondary"
    header-text-variant="white"
    hide-footer
    no-stacking
    scrollable
    @hidden="resetModal"
  >
    <b-overlay :show="loading" no-wrap></b-overlay>
    <mn-table
      :items="items"
      :headers="headers"
      enabledHeader
      class="client-table"
    >
      <template v-slot:actions="{ item }">
        <b-button
          v-if="item.public_install_page_url"
          variant="link"
          size="sm"
          target="_blank"
          :href="item.public_install_page_url"
        >
          <font-awesome-icon :icon="['far', 'eye']" size="lg" />
        </b-button>
      </template>
    </mn-table>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import MnTable from "@/components/common/table/MnTable";

export default {
  props: {
    build_slug: {
      type: String,
      require: true
    }
  },
  components: {
    MnTable
  },
  data() {
    return {
      show: null,
      loading: null,
      items: [],
      headers: [
        {
          name: "Title",
          field: "title",
          class: "text-break"
        },
        {
          name: "Version",
          field: "version"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      token: "auth/token"
    })
  },
  mounted() {
    this.getAll();
    this.show = true;
  },
  methods: {
    async getAll() {
      try {
        this.loading = true;
        this.items = await this.$store.dispatch("app/getArtifactsForClient", {
          build_slug: this.build_slug
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    resetModal() {
      this.show = false;
      this.$router.go(-1);
    }
  }
};
</script>
